#footer {
    color: #444444;
    font-size: 14px;
    background: #f1f0f2;
  }
  #footer a {
      color : #007CB4;
  }
  #footer .credits {
    padding-top: 5px;
    font-size: 13px;
    color: #444444;
  }