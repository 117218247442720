.about .content {
    padding: 30px;
    background: #007CB4;
    border-radius: 4px;
    color: #fff;
  }
  
  .about .content h3 {
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 30px;
  }
  
  .about .content p {
    margin-bottom: 30px;
  }
  
  .about .content .more-btn {
    display: inline-block;
    background: rgba(255, 255, 255, 0.1);
    padding: 6px 30px 8px 30px;
    color: #fff;
    border-radius: 50px;
    transition: all ease-in-out 0.4s;
  }
  
  .about .content .more-btn i {
    font-size: 14px;
  }
  
  .about .content .more-btn:hover {
    text-decoration: none;
    color: #007CB4;
    background: #fff;
  }
  
  .about .icon-boxes .icon-box {
    text-align: center;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 40px 30px;
    width: 100%;
    transition: 0.3s;
  }
  
  .about .icon-boxes .icon-box svg {
    font-size: 40px;
    color: #007CB4;
    margin-bottom: 30px;
  }
  
  .about .icon-boxes .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 30px 0;
  }
  
  .about .icon-boxes .icon-box p {
    font-size: 15px;
    color: #848484;
  }
  
  .about .icon-boxes .icon-box:hover {
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
  }