#header {
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    top: 20px;
  }
  
  #header .header-container {
    background: #fff;
  }
  
  #header.header-scrolled {
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    top: 0;
  }
  
  #header .logo {
    overflow: hidden;
    padding: 16px 30px 12px 30px;
  }
  #header .logo img {
    padding: 0;
    margin: 0;
    max-height: 60px;
    width: 60px;
  }
  
  @media (max-width: 992px) {
    #header {
      top: 20px;
    }
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  .nav-menu, .nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
    float: left;
  }
  
  .nav-menu li {
    display: block;
    position: relative;
    color: #36343a;
    padding: 10px 15px;
    transition: 0.3s;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
  }
  
  .nav-menu li:hover, .nav-menu .active , .nav-menu li:hover  {
    color: #007CB4;
    text-decoration: none;
  }